<template>
  <b-row>
    <b-col cols="12">
      <b-card title="Dates">
        <div>
          <b-form @submit.prevent="submitForm">
            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <flat-pickr
                    v-model="multidate"
                    class="form-control"
                    :config="{
                      mode: 'multiple',
                      dateFormat: 'Y-m-d',
                      inline: true,
                    }"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" class="text-end">
                <b-button type="submit" variant="primary" class="mr-1 mt-1">
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card title="Set Non Delivery Time">
        <div>
          <b-form @submit.prevent="submitForm3">
            <b-row>
              <b-col cols="6">
                <div>
                  <v-select
                    v-model="order_type"
                    label="title"
                    :options="options"
                  />
                </div>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <flat-pickr
                    v-model="deliveryDate"
                    placeholder="Choose Date"
                    class="form-control"
                    :config="{
                      dateFormat: 'Y-m-d',
                    }"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" class="text-end">
                <b-button type="submit" variant="primary" class="mr-1 mt-1">
                  Search
                </b-button>
              </b-col>
            </b-row>
          </b-form>

          <b-row>
            
            <b-col cols="12" lg="12">
              <div>
                <div v-if="deltime1.length > 0">
                  <h5 class="text-center">Morning</h5>
                  <div>
                    <ul class="list-unstyled d-flex flex-wrap">
                      <li v-for="(m, a) in deltime1" class="m-1">
                        <b-button variant="outline-dark" @click="addtime(m)">
                          {{ m }}
                        </b-button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-if="deltime2.length > 0">
                  <h5 class="text-center">Evening</h5>
                  <div>
                    <!-- @click="changeTime(m)" -->
                    <ul class="list-unstyled d-flex flex-wrap">
                      <li v-for="(m, a) in deltime2" class="m-1">
                        <b-button variant="outline-dark" @click="addtime(m)">
                          {{ m }}
                        </b-button>
                      </li>
                    </ul>
                  </div>
                </div>

               
              </div>
            </b-col>
            <b-col cols="12">
              <div class="mt-1" v-if="order_type.title == 'Delivery'">
                <div v-for="x in deliverytimes">
                  <ul
                    v-if="x.date == deliveryDate"
                    class="list-unstyled d-flex flex-wrap"
                  >
                    <li v-for="y in x.time" class="m-1">
                      <b-button variant="outline-danger">
                        {{ y }}
                      </b-button>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="mt-1" v-if="order_type.title == 'Takeaway'">
                <div v-for="x in takeawaytimes">
                  <ul
                    v-if="x.date == deliveryDate"
                    class="list-unstyled d-flex flex-wrap"
                  >
                    <li v-for="y in x.time" class="m-1">
                      <b-button variant="outline-danger">
                        {{ y }}
                      </b-button>
                    </li>
                  </ul>
                </div>
              </div>
              <div v-if="deltime2.length > 0 || deltime1.length > 0">
                  <div>
                    <b-button
                      type="button"
                      @click="submitForm4"
                      variant="primary"
                      class="mr-1 mt-1"
                    >
                      Submit
                    </b-button>
                  </div>
                </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>

    <b-col cols="12">
      <b-card title="Lunch Buffet Pdf">
        <div>
          <b-form @submit.prevent="submitForm1">
            <b-row>
              <b-col cols="12">
                <div>
                  <b-form-file id="extension" v-model="pdf" accept=".pdf" />
                </div>
              </b-col>

              <b-col cols="12" class="text-end">
                <b-button type="submit" variant="primary" class="mr-1 mt-1">
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";

import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import axios from "@axios";

export default {
  async mounted() {
    await this.fetchdelivery();
  },
  components: {
    vSelect,
    BFormFile,
    BForm,
    BRow,
    BCol,
    BCard,
    BButton,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    flatPickr,
  },

  data() {
    return {
      multidate: null,
      pdf: null,
      deliveryDate: null,
      order_type: {
        title: "",
      },
      deltime1: [],
      deltime2: [],
      options: [{ title: "Delivery" }, { title: "Takeaway" }],
      takeawaytimes: [],
      deliverytimes: [],
      ctime: null,
    };
  },

  methods: {
    addtime(time) {
      this.ctime = time;
      console.log(time);
      console.log(this.deliveryDate);
      if (this.order_type.title == "Delivery") {
        var index = this.deliverytimes.findIndex(
          (x) => x.date == this.deliveryDate
        );
        if (index >= 0) {
          if (this.deliverytimes[index].time.indexOf(time) >= 0) {
            var timeindex = this.deliverytimes[index].time.indexOf(time);
            this.deliverytimes[index].time.splice(timeindex, 1);
          } else {
            this.deliverytimes[index].time.push(time);
          }
        } else {
          let cdate = {
            date: this.deliveryDate,
            time: [],
          };
          cdate.time.push(time);
          this.deliverytimes.push(cdate);
        }
      } else if (this.order_type.title == "Takeaway") {
        var index = this.takeawaytimes.findIndex(
          (x) => x.date == this.deliveryDate
        );
        if (index >= 0) {
          if (this.takeawaytimes[index].time.indexOf(time) >= 0) {
            var timeindex = this.takeawaytimes[index].time.indexOf(time);
            this.takeawaytimes[index].time.splice(timeindex, 1);
          } else {
            this.takeawaytimes[index].time.push(time);
          }
        } else {
          let cdate = {
            date: this.deliveryDate,
            time: [],
          };
          cdate.time.push(time);
          this.takeawaytimes.push(cdate);
        }
      }
    },

    async submitForm() {
      let formData = {};
      formData.date = this.multidate;
      await axios
        .post("admin/add/deliverydate", formData)
        .then(function (response) {
          if (response.data.result == 1) {
            alert("Success");
          } else {
            alert("Error");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async submitForm3() {
      let formData3 = new FormData();
      formData3.append("deliveryDate", this.deliveryDate);
      formData3.append("order_type", this.order_type.title);
      let time1;
      let time2;

      await axios
        .post("user/get/time", formData3)
        .then(function (response) {
          if (response.data.result == 1) {
            time1 = response.data.time1;
            time2 = response.data.time2;
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      this.deltime1 = time1;
      this.deltime2 = time2;
    },

    async submitForm4() {
      let formData4 = new FormData();
      formData4.append("deliverytimes", JSON.stringify(this.deliverytimes));
      formData4.append("takeawaytimes", JSON.stringify(this.takeawaytimes));
      await axios
        .post("admin/add/deliverytime", formData4)
        .then(function (response) {
          if (response.data.result == 1) {
            alert("Success");
          } else {
            alert("Error");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async submitForm1() {
      let formData1 = new FormData();
      formData1.append("pdf", this.pdf);
      await axios
        .post("admin/add/lunchpdf", formData1)
        .then(function (response) {
          if (response.data.result == 1) {
            alert("Success");
          } else {
            alert("Error");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async fetchdelivery() {
      const promises = [axios.get(`admin/get/deliverydate`)];
      const allResponses = await Promise.all(promises);
      this.multidate = allResponses[0].data.data;

      this.deliverytimes = JSON.parse(allResponses[0].data.data1.value);
      this.takeawaytimes = JSON.parse(allResponses[0].data.data1.value2);
      console.log(this.deliverytimes);
      console.log(this.takeawaytimes);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
